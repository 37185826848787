.content-wrap {
    border: 1px solid transparent;
    border-radius: 5px;
    padding-top: 10px;
    background-color: #fff;
    display: block;
    text-decoration: none;
    overflow: hidden;
    height: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    .responsive(@smartphone-width, {
        padding-top: 0;
        margin-bottom: 20px;
    });

    &:hover {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        border-color: rgba(0, 196, 219, .4);
    }

    &.isCoverImageExisted {
        padding-top: 0px;
    }

    em {
        font-style: normal;
        background-color: #FFCB8E;
        box-sizing: border-box;
        outline: 1.5px solid #FFCB8E;
        padding: 0 1px;
        margin: 0 1px;
    }
}

.content-info {
    padding: 30px 36px 30px;
    display: flex;
    flex-direction: column;
}

.cover-image-wrap {
    width: 108%;
    text-align: center;
    margin-left: -4%;
}

.cover-image {
    width: 100%;
}

.content-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 900;
    color: #494953;
    .responsive(@smartphone-width, {
        font-size: 18px;
        line-height: 22px;
    });
}

.content-summary {
    flex: 1 1 auto;
    position: relative;
    font-size: 14px;
    line-height: 25px;
    color: rgba(0, 0, 0, .5);
    margin: 12px 0 6px;
    min-height: 1px;
    max-height: 124px;
    overflow: hidden;
    .responsive(@smartphone-width, {
        font-size: 14px;
        line-height: 25px;
        margin: 10px 0 4px;
    });

    &.isImage {
        margin-top: 0;
    }
}

.content-last-updated {
    position: relative;
    margin-top: 50px;

    &:after {
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        border-top: 1px solid #C4C4C4;
        top: -25px;
        width: 28px;

        .responsive(@smartphone-width, {
            bottom: -16px;
        });
    }
}

.content-last-updated,
.content-last-updated span {
    color: rgba(0, 0, 0, .4) !important;
    font-size: 14px;
    line-height: 25px;

    .responsive(@smartphone-width, {
        font-size: 12px;
        line-height: 21px;
    });
}
