.folder-wrap {
    height: 90%;
    border-radius: 5px;
    background: #FEFEFE;
    box-sizing: border-box;
    color: rgba(0, 0, 0, .5);
    border: 1px solid transparent;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    transition: all .3s;

    .responsive(@smartphone-width, {
        height: auto;
        margin-bottom: 12px;
    });

    &:hover {
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        border-color: rgba(0, 196, 219, .4);
    }
}

.folder-content {
    padding: 34px 35px 26px;
}

.folder-cover-image {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    height: 180px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-position: center;
    background-size: cover;
}

.folder-icon {
    vertical-align: text-bottom;
    font-size: 23px;
    color: #00C4DB;
    &:after {
        font-family: 'kipico';
        content: '\ea03';
    }
}

.folder-title {
    font-size: 22px;
    line-height: 31px;
    color: #494953;
    margin: 14px 0 12px;
    .responsive(@smartphone-width, {
        font-size: 18px;
        line-height: 22px;
        margin: 22px 0 10px;
    });
}

.folder-description {
    flex: 1 1 0;
    font-size: 14px;
    line-height: 25px;
    color: rgba(0, 0, 0, .5);
    overflow: hidden;
    .responsive(@smartphone-width, {
        font-size: 14px;
        line-height: 25px;
    });
}

.folder-detail {
    color: rgba(0, 0, 0, .4);
    font-size: 12px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    display: none;  // NOTE: Hide the line of "N folders" for now until we have documents. (https://trello.com/c/mY2bzHdJ)
    .responsive(@smartphone-width, {
        font-size: 12px;
        line-height: 21px;
        padding-top: 6px;
    });
}

.folder-detail-separator {
    display: block;
    height: 4px;
    width: 4px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: #CBCBCB;
}
