.status_wrap {
    text-align: center;
    margin: 50px 0 40px;
    .responsive(@smartphone-width, {
        margin-top: 42px;
    });

    h1 {
        font-size: 32px;
        line-height: 38px;
        font-weight: 900;
        .responsive(@smartphone-width, {
            font-size: 18px;
            line-height: 22px;
        });
    }

    p {
        font-size: 22px;
        line-height: 26px;
        margin: 12px 0 66px;
        .responsive(@smartphone-width, {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 56px;
        });
    }

    .status_img {
        background-size: contain;
        background-repeat: no-repeat;
        max-width: 100%;
        margin: auto;
        text-indent: -9999px;
        .responsive(@smartphone-width, {
            max-width: 80%;
        });
    }

    .status_empty {
        width: 438px;
        height: 394px;
        background-image: url('../img/status_empty.png');
        .responsive(580px, {
            height: 0;
            padding-top: 72%;
        });
    }

    .status_no_result {
        width: 476px;
        height: 420px;
        background-image: url('../img/status_no_result.png');
        .responsive(624px, {
            height: 0;
            padding-top: 71%;
        });
    }

    .status_not_found {
        width: 528px;
        height: 504px;
        background-image: url('../img/status_not_found.png');
        .responsive(692px, {
            height: 0;
            padding-top: 77%;
        });
    }

    .status_error {
        width: 632px;
        height: 413px;
        background-image: url('../img/status_error.png');
        .responsive(@smartphone-width, {
            height: 0;
            padding-top: 53%;
        });
    }

    .status_no_access_righe {
        width: 433px;
        height: 439px;
        background-image: url('../img/status_no_access_right.png');
        .responsive(572px, {
            height: 0;
            padding-top: 81%;
        });
    }

    @media
    screen and (-webkit-min-device-pixel-ratio: 1.5),
    screen and (-moz-min-device-pixel-ratio: 1.5),
    screen and (min-device-pixel-ratio: 1.5) {
        .status_no_access_righe {
            background-image: url('../img/status_no_access_right@2x.png');
        }
        .status_empty {
            background-image: url('../img/status_empty@2x.png');
        }
        .status_no_result {
            background-image: url('../img/status_no_result@2x.png');
        }
        .status_not_found {
            background-image: url('../img/status_not_found@2x.png');
        }
        .status_error {
            background-image: url('../img/status_error@2x.png');
        }
    }
}