.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0
}

[data-tippy-root] {
  max-width: calc(100vw - 10px)
}

.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.4;
  outline: 0;
  transition-property: transform, visibility, opacity
}

.tippy-box[data-placement^=top] > .tippy-arrow {
  bottom: 0
}

.tippy-box[data-placement^=top] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top
}

.tippy-box[data-placement^=bottom] > .tippy-arrow {
  top: 0
}

.tippy-box[data-placement^=bottom] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom
}

.tippy-box[data-placement^=left] > .tippy-arrow {
  right: 0
}

.tippy-box[data-placement^=left] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left
}

.tippy-box[data-placement^=right] > .tippy-arrow {
  left: 0
}

.tippy-box[data-placement^=right] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right
}

.tippy-box[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid
}

.tippy-content {
  position: relative;
  z-index: 1
}

.tippy-box[data-theme~=light] {
  color: #26323d;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, .15), 0 4px 80px -8px rgba(36, 40, 47, .25), 0 4px 4px -2px rgba(91, 94, 105, .15);
  background-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=top] > .tippy-arrow:before {
  border-top-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=bottom] > .tippy-arrow:before {
  border-bottom-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=left] > .tippy-arrow:before {
  border-left-color: #fff
}

.tippy-box[data-theme~=light][data-placement^=right] > .tippy-arrow:before {
  border-right-color: #fff
}

.tippy-box[data-theme~=light] > .tippy-backdrop {
  background-color: #fff
}

.tippy-box[data-theme~=light] > .tippy-svg-arrow {
  fill: #fff
}
