@import "reset";
@import "mixins";
@import "grid.min.css";
@import "~@kipwise/toolbox/lib/Icon.css";

:global {
  @import "folder_card";
  @import "content_card";
  @import "status";
  @import "tippy";

  @dark-text-color: #5A5773;

  html {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  body {
    background-color: #F8F8FC;
    color: #000;
    font-family: "Lato", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    &.article {
      padding-bottom: 72px;
    }
    &.image {
      background-color: #ddd;
    }
  }

  input {
    -webkit-appearance: none;  // let Safari apply input css style
    font-family: "Lato", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .content-cover-image {
    height: 270px;
    width: 100%;
    margin-top: 70px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .responsive(@smartphone-width, {
      margin-top: 0;
      height: 120px;
    });
  }

  .container-wrapper {
    min-height: calc(100vh - 100px);

    &.without-top-section {
      min-height: calc(100vh - 170px);
      margin-top: 70px;
      overflow: hidden;
      .responsive(@smartphone-width, {
        min-height: calc(100vh - 100px);
        margin-top: 0;
      });
    }
  }

  .section-row {
    margin: 48px -1rem 100px;
    .responsive(@smartphone-width, {
      margin: 12px -1rem 40px;
    });
  }

  @link-color: #00C4DB;

  .box{ height: 100%; }

  .section {
    padding: 48px auto
  }

  .section-header {
    text-align: center;
    margin: 12px auto;
    font-size: 36px;
    color: @dark-text-color
  }

  .section-subheader {
    margin: 12px auto;
    display: block;
    max-width: 600px;
    font-size: 14px;
    color: #666;
  }

  .section-description {
    color: #888;
    text-align: center;
  }

  .categories-title {
    font-size: 16px;
    line-height: 28px;
    color: #000;
    margin-bottom: 20px;
    font-weight: normal;
    .responsive(@smartphone-width, {
      font-size: 16px;
      line-height: 28px;
    });

    &:first-child {
      margin-top: 40px;
    }
  }

  a {
    .transition(all linear .1s);
    color: @link-color;
    &:hover, &:active, &:focus {
      color: darken(@link-color, 10%);
      cursor: pointer;
    }
  }

  .container {
    margin: 0 auto;
    max-width: 1220px;
    position: relative;
    .responsive(@smartphone-width, { padding-left: 15px; padding-right: 15px; })
  }

  .navbar {
    height: 100%;
    width: 100%;
  }

  .navbar-brand {
    text-decoration: none;
    color: @dark-text-color;
    display: flex;
    height: 70px;
    align-items: center;

    &:hover {
      color: @dark-text-color;
    }
  }

  .header-block {
    z-index: 10;
    position: fixed;
    display: flex;
    width: 100%;
    top: 0;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    transition: all .3s linear;
    box-shadow: 0 0 10px transparent;

    .responsive(@smartphone-width, {
      justify-content: start;
      position: relative;
    });

    &.isFixed {
      box-shadow: 0 4px 25px rgba(0, 0, 0, 0.12);
      .responsive(@smartphone-width, {
        box-shadow: none;
      });
    }

    .container {
      height: inherit;
    }

    .name {
      max-width: 320px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      padding-left: 20px;
      font-size: 16px;
      font-weight: normal;
      .responsive(@smartphone-width, { font-size: 16px; padding-left: 15px; });
    }

    img {
      height: 45px;
      max-height: initial;
      border-radius: 3px;
      vertical-align: middle;
      .responsive(@smartphone-width, {
        height: 36px;
        border-radius: 3px;
      });
    }
  }

  .homepage-hero {
    height: 420px;
    margin-top: 0 !important;
    .responsive(@smartphone-width, { height: 200px });
  }

  .powered-by {
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
    color: #333;
    padding-bottom: 36px;
  }


  .search-wrap {
    padding: 142px 0 172px;
    .responsive(@smartphone-width, { padding: 30px 0 22px; });
  }

  .search-bar-wrap {
    position: relative;
    margin-top: 16px;
    .responsive(@smartphone-width, {
      margin-top: 20px;
    });

    label {
      position: absolute;
      height: 100%;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #93939D;
    }

    input {
      border-radius: 6px;
      font-size: 16px;
      border: 1px solid transparent;
      width: 100%;
      height: 70px;
      max-width: 760px;
      padding: 0 26px 0 45px;
      display: block;
      caret-color: #00C4DB;
      .responsive(@smartphone-width, {
        height: 60px;
      });
    }

    .search-bar-icon {
      font-size: 23px;
    }
  }

  .search-bar-icon {
    &:after {
      font-family: 'kipico';
      content: '\ea04';
    }
  }

  .search-title {
    font-size: 36px;
    line-height: 43px;
    font-weight: 900;
    .responsive(@smartphone-width, {
      font-size: 22px;
      line-height: 12px;
      margin-top: 16px;
    });
  }

  .search-hint {
    font-size: 48px;
    font-weight: 900;

    .responsive(@smartphone-width, {
      font-size: 26px;
    });
  }

  .search-result-description {
    font-size: 22px;
    line-height: 38px;
    margin: 40px 0 20px;
    .responsive(@smartphone-width, {
      font-size: 16px;
      line-height: 28px;
    });

    em {
      color: #00C4DB !important;
      font-style: normal;
    }
  }

  .search-page-bar-wrap {
    margin-bottom: 14px;
    .responsive(@smartphone-width, {
      margin-bottom: 0;
    });
  }

  footer {
    margin: auto;
    height: 160px;
    display: flex;
    align-items: center;
    border-top: 1px solid #E7E7E7;
    background: #fff;
    box-sizing: border-box;
    padding-bottom: 40px;

    .powered-by {
      font-size: 16px;
      display: block;
      margin: auto;
      color: rgba(0, 0, 0, .5);

      .responsive(@smartphone-width, {
        font-size: 12px;
      });

      a {
        color: rgba(0, 0, 0, .5);
        text-decoration: none;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .powered-by-logo {
        background-size: contain;
        background-repeat: no-repeat;
        width: 110px;
        height: 32px;
        display: inline-block;
        text-indent: -9999px;
        background-image: url('../img/kipwise_logo.png');
        .responsive(@smartphone-width, {
          width: 80px;
          height: 26px;
        });
      }

      @media
      screen and (-webkit-min-device-pixel-ratio: 1.5),
      screen and (-moz-min-device-pixel-ratio: 1.5),
      screen and (min-device-pixel-ratio: 1.5) {
          .powered-by-logo {
              background-image: url('../img/kipwise_logo@2x.png');
          }
      }
    }
  }

  .breadcrumbs {
    padding: 34px 0 24px;
    .responsive(@smartphone-width, {
      padding: 20px 0 10px;
    });
    a {
      position: relative;
      font-size: 18px;
      text-decoration: none;
      color: #fff;
      .transition(all linear .1s);
      .responsive(@smartphone-width, {
        font-size: 14px;
      });
      &:after {
        content: "\ea15";
        font-family: 'kipico';
        margin: 0 14px;
        font-size: 10px;
        .responsive(@smartphone-width, {
          font-size: 9px;
        });
      }
      // underline with better distance
      &:before {
        content: '';
        width: calc(100% - 34px);
        position: absolute;
        bottom: -3px;
        border-bottom: solid;
        border-width: 1px;
        opacity: 0;
        .transition(all linear .1s);
      }
      &:hover:before {
        opacity: 1;
      }
    }
  }

  .article-content {
    max-width: 720px;
    .title {
      text-align: left;
      margin: 12px auto;
      font-size: 36px
    }
    .body {
      margin-top: 10px
    }
    .last-updated {
      font-size: 14px;
      color: #999;
    }
    .content-placeholder-loading {
      margin-top: 46px;
    }
  }

  .bar {
    margin: 16px 0;
    height: 16px;
    overflow: hidden;
    position: relative;
    display: block;
    background-color: rgba(0, 0, 0, .1);
  }
  .bar::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    background: linear-gradient(to right, rgba(255, 255, 255, .3) 46%, rgba(255, 255, 255, .1) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
    animation: loading 1.2s linear infinite;
  }

  .search-result {
    em {
      font-style: normal;
      background-color: #ffff00;
    }
  }

  .header-container-wrap {
    height: 100%;
    align-items: center;
    .responsive(@smartphone-width, {
      // flex-direction: column;
    });
  }

  .header-search-form {
    display: inline-block;
    width: 100%;
    max-width: 320px;
    text-align: right;
    position: relative;
    float: right;
    .responsive(@smartphone-width, { max-width: 100%; margin-bottom: 20px; });
    label {
      position: absolute;
      left: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12%;
      font-size: 17px;
      color: #93939D;
    }
    input {
      font-size: 14px;
      padding: 8px 12px 8px 12%;
      display: inline-block;
      border-radius: 6px;
      background: #F8F8FC;
      width: 100%;
      height: 50px;
      border: 1px solid transparent;
    }
  }

  .header-cta {
    color: #00C4DB;
    text-decoration: none;
    float: right;
    margin-top:4px;
    font-size: 16px;
    .responsive(@smartphone-width, { display: none });
  }

  .topic-background-wrap {
    background-color: #00C4DB
  }

  .page-info-wrap {
    color: #fff;
    background-color: #00C4DB;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &.has-image {
      opacity: .67;
    }
  }

  .page-header-wrap {
    position: relative;
    margin-top: 70px;
    .responsive(@smartphone-width, { margin-top: 0 });
    &.has-image {
      margin-top: 0;
      .breadcrumbs {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    .page-content {
      color: #fff;
      position: relative;
      z-index:2;
      padding: 0;
    }

    .page-info-title {
      font-size: 36px;
      font-weight: 900;
      line-height: 43px;
      margin: 18px 0 15px;
      .responsive(@smartphone-width, {
        margin: 12px 0;
        font-size: 22px;
        line-height: 26px;
      });
    }

    .page-info-subheader {
      font-size: 22px;
      line-height: 38px;
      margin-bottom: 38px;
      opacity: .7;
      .responsive(@smartphone-width, {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 12px;
      });
    }

    a {
      color: #fff;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .content-page-title {
    max-width: @smartphone-width;
  }

  @keyframes loading {
    0% {
      -webkit-transform: translate3d(-30%, 0, 0);
      transform: translate3d(-30%, 0, 0); }
    100% {
      -webkit-transform: translate3d(30%, 0, 0);
      transform: translate3d(30%, 0, 0); } }

  @media print {
    html {
      overflow: visible;
    }
    .header-block {
      display: none;
    }
    .page-info-wrap {
      padding-top: 0 !important;
    }
  }
}
