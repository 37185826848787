@font-face {
    font-family: 'kipico';
    src:  url('fonts/kipico.eot?sa1c07');
    src:  url('fonts/kipico.eot?sa1c07#iefix') format('embedded-opentype'),
    url('fonts/kipico.ttf?sa1c07') format('truetype'),
    url('fonts/kipico.woff?sa1c07') format('woff'),
    url('fonts/kipico.svg?sa1c07#kipico') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.kipico {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'kipico' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.folder-lg:before {
    content: "\ea03";
}
.search-lg:before {
    content: "\ea04";
}
.permission-public:before {
    content: "\e94d";
}
.link:before {
    content: "\e95a";
}
.review:before {
    content: "\e932";
}
.embed:before {
    content: "\e956";
}
.public-site:before {
    content: "\e94e";
}
.content-folder:before {
    content: "\e934";
}
.dashboard:before {
    content: "\e942";
}
.close:before {
    content: "\e937";
}
.move:before {
    content: "\e944";
}
.plus-circle:before {
    content: "\e940";
}
.uncategorized:before {
    content: "\e945";
}
.notification:before {
    content: "\e946";
}
.questions:before {
    content: "\e947";
}
.resize:before {
    content: "\e935";
}
.content-document:before {
    content: "\e94a";
}
.content-document-approved:before {
    content: "\e93d";
}
.content-template:before {
    content: "\e943";
}
.content-review:before {
    content: "\e948";
}
.create-document:before {
    content: "\e949";
}
.looker:before {
    content: "\eac9";
}
.notion:before {
    content: "\eac5";
}
.freshservice:before {
    content: "\eac4";
    color: #09c6fa;
}
.pingid:before {
    content: "\eac0";
    color: #bc1f27;
}
.azure-active-directory:before {
    content: "\eabf";
    color: #00bef2;
}
.onelogin .path1:before {
    content: "\eabc";
    color: rgb(28, 31, 42);
}
.onelogin .path2:before {
    content: "\eabd";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.salesforce .path1:before {
    content: "\eaae";
    color: rgb(0, 161, 224);
}
.salesforce .path2:before {
    content: "\eaaf";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.salesforce .path3:before {
    content: "\eab0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.salesforce .path4:before {
    content: "\eab1";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.salesforce .path5:before {
    content: "\eab2";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.salesforce .path6:before {
    content: "\eab3";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.salesforce .path7:before {
    content: "\eab4";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.salesforce .path8:before {
    content: "\eab5";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.salesforce .path9:before {
    content: "\eab6";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.salesforce .path10:before {
    content: "\eab7";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.salesforce .path11:before {
    content: "\eab8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.okta:before {
    content: "\eab9";
    color: #00297a;
}
.jumpcloud .path1:before {
    content: "\eaba";
    color: rgb(179, 233, 231);
}
.jumpcloud .path2:before {
    content: "\eabb";
    margin-left: -1em;
    color: rgb(20, 161, 156);
}
.kayako .path1:before {
    content: "\ea9d";
    color: rgb(250, 129, 118);
}
.kayako .path2:before {
    content: "\ea9e";
    margin-left: -1em;
    color: rgb(243, 112, 54);
}
.kayako .path3:before {
    content: "\ea9f";
    margin-left: -1em;
    color: rgb(241, 87, 19);
}
.kayako .path4:before {
    content: "\eaa0";
    margin-left: -1em;
    color: rgb(133, 184, 193);
}
.kayako .path5:before {
    content: "\eaa1";
    margin-left: -1em;
    color: rgb(243, 157, 144);
}
.kayako .path6:before {
    content: "\eaa2";
    margin-left: -1em;
    color: rgb(105, 170, 181);
}
.kayako .path7:before {
    content: "\eaa3";
    margin-left: -1em;
    color: rgb(255, 177, 162);
}
.kayako .path8:before {
    content: "\eaa4";
    margin-left: -1em;
    color: rgb(247, 111, 109);
}
.freshdesk .path1:before {
    content: "\ea9b";
    color: rgb(37, 193, 111);
}
.freshdesk .path2:before {
    content: "\ea9c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.kustomer .path1:before {
    content: "\ea98";
    color: rgb(0, 0, 0);
}
.kustomer .path2:before {
    content: "\ea99";
    margin-left: -1em;
    color: rgb(255, 101, 93);
}
.drift:before {
    content: "\ea9a";
}
.buzzsprout .path1:before {
    content: "\ea92";
    color: rgb(131, 188, 69);
}
.buzzsprout .path2:before {
    content: "\ea93";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.buzzsprout .path3:before {
    content: "\ea94";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.buzzsprout .path4:before {
    content: "\ea95";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.buzzsprout .path5:before {
    content: "\ea96";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.monday .path1:before {
    content: "\ea8b";
    color: rgb(246, 43, 84);
}
.monday .path2:before {
    content: "\ea8c";
    margin-left: -1em;
    color: rgb(255, 204, 0);
}
.monday .path3:before {
    content: "\ea8d";
    margin-left: -1em;
    color: rgb(0, 202, 114);
}
.jira .path1:before {
    content: "\ea7e";
    color: rgb(38, 132, 255);
}
.jira .path2:before {
    content: "\ea7f";
    margin-left: -1em;
    color: rgb(0, 82, 204);
}
.jira .path3:before {
    content: "\ea81";
    margin-left: -1em;
    color: rgb(0, 82, 204);
}
.codesandbox:before {
    content: "\ea7a";
}
.linkedin:before {
    content: "\ea6d";
    color: #0977b6;
}
.twitter:before {
    content: "\ea70";
    color: #55acee;
}
.instagram:before {
    content: "\ea72";
    color: #cf0073;
}
.facebook:before {
    content: "\ea73";
    color: #395185;
}
.gmail .path1:before {
    content: "\ea67";
    color: rgb(66, 133, 244);
}
.gmail .path2:before {
    content: "\ea68";
    margin-left: -1em;
    color: rgb(52, 168, 83);
}
.gmail .path3:before {
    content: "\ea69";
    margin-left: -1em;
    color: rgb(251, 188, 4);
}
.gmail .path4:before {
    content: "\ea6a";
    margin-left: -1em;
    color: rgb(234, 67, 53);
}
.gmail .path5:before {
    content: "\ea6b";
    margin-left: -1em;
    color: rgb(197, 34, 31);
}
.miro .path1:before {
    content: "\ea5b";
    color: rgb(255, 208, 47);
}
.miro .path2:before {
    content: "\ea5c";
    margin-left: -1em;
    color: rgb(5, 4, 56);
}
.miro .path3:before {
    content: "\ea5d";
    margin-left: -1em;
    color: rgb(5, 4, 56);
}
.miro .path4:before {
    content: "\ea5e";
    margin-left: -1em;
    color: rgb(5, 4, 56);
}
.firefox .path1:before {
    content: "\ea1c";
    color: rgb(255, 54, 71);
}
.firefox .path2:before {
    content: "\ea1d";
    margin-left: -1em;
    color: rgb(255, 152, 14);
}
.firefox .path3:before {
    content: "\ea1e";
    margin-left: -1em;
    color: rgb(119, 22, 168);
}
.firefox .path4:before {
    content: "\ea1f";
    margin-left: -1em;
    color: rgb(255, 126, 55);
}
.firefox .path5:before {
    content: "\ea20";
    margin-left: -1em;
    color: rgb(255, 152, 14);
}
.firefox .path6:before {
    content: "\ea21";
    margin-left: -1em;
    color: rgb(255, 152, 14);
}
.draw-io .path1:before {
    content: "\ea22";
    color: rgb(240, 135, 5);
}
.draw-io .path2:before {
    content: "\ea23";
    margin-left: -1em;
    color: rgb(223, 108, 12);
}
.draw-io .path3:before {
    content: "\ea24";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.vimeo:before {
    content: "\ea25";
    color: #32b8e8;
}
.loom:before {
    content: "\ea26";
    color: #fd5e60;
}
.codepen:before {
    content: "\ea27";
}
.helpscout:before {
    content: "\ea28";
    color: #1292ee;
}
.hubspot:before {
    content: "\ea29";
    color: #fa7820;
}
.youtube .path1:before {
    content: "\ea2a";
    color: rgb(255, 255, 255);
}
.youtube .path2:before {
    content: "\ea2b";
    margin-left: -1em;
    color: rgb(66, 0, 0);
    opacity: 0.12;
}
.youtube .path3:before {
    content: "\ea2c";
    margin-left: -1em;
    color: rgb(229, 45, 39);
}
.wistia .path1:before {
    content: "\ea2d";
    color: rgb(20, 142, 224);
}
.wistia .path2:before {
    content: "\ea2e";
    margin-left: -1em;
    color: rgb(84, 187, 255);
}
.google .path1:before {
    content: "\ea0d";
    color: rgb(66, 133, 244);
}
.google .path2:before {
    content: "\ea0e";
    margin-left: -1em;
    color: rgb(52, 168, 83);
}
.google .path3:before {
    content: "\ea0f";
    margin-left: -1em;
    color: rgb(251, 188, 5);
}
.google .path4:before {
    content: "\ea10";
    margin-left: -1em;
    color: rgb(235, 67, 53);
}
.microsoft .path1:before {
    content: "\ea09";
    color: rgb(243, 83, 37);
}
.microsoft .path2:before {
    content: "\ea0a";
    margin-left: -1em;
    color: rgb(129, 188, 6);
}
.microsoft .path3:before {
    content: "\ea0b";
    margin-left: -1em;
    color: rgb(5, 166, 240);
}
.microsoft .path4:before {
    content: "\ea0c";
    margin-left: -1em;
    color: rgb(255, 186, 8);
}
.onedrive .path1:before {
    content: "\ea05";
    color: rgb(3, 100, 184);
}
.onedrive .path2:before {
    content: "\ea06";
    margin-left: -1em;
    color: rgb(0, 120, 212);
}
.onedrive .path3:before {
    content: "\ea07";
    margin-left: -1em;
    color: rgb(20, 144, 223);
}
.onedrive .path4:before {
    content: "\ea08";
    margin-left: -1em;
    color: rgb(40, 168, 234);
}
.safari .path1:before {
    content: "\e96a";
    color: rgb(219, 219, 218);
}
.safari .path2:before {
    content: "\e977";
    margin-left: -1em;
    color: rgb(80, 138, 255);
}
.safari .path3:before {
    content: "\e979";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path4:before {
    content: "\e97a";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path5:before {
    content: "\e97b";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path6:before {
    content: "\e97c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path7:before {
    content: "\e97d";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path8:before {
    content: "\e97e";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path9:before {
    content: "\e97f";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path10:before {
    content: "\e980";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path11:before {
    content: "\e981";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path12:before {
    content: "\e982";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path13:before {
    content: "\e983";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path14:before {
    content: "\e984";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path15:before {
    content: "\e985";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path16:before {
    content: "\e986";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path17:before {
    content: "\e987";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path18:before {
    content: "\e988";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path19:before {
    content: "\e989";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path20:before {
    content: "\e98a";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path21:before {
    content: "\e98b";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path22:before {
    content: "\e98c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path23:before {
    content: "\e98d";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path24:before {
    content: "\e98e";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path25:before {
    content: "\e98f";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path26:before {
    content: "\e990";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path27:before {
    content: "\e991";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path28:before {
    content: "\e992";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path29:before {
    content: "\e993";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path30:before {
    content: "\e994";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path31:before {
    content: "\e995";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path32:before {
    content: "\e996";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path33:before {
    content: "\e997";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path34:before {
    content: "\e998";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path35:before {
    content: "\e999";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path36:before {
    content: "\e99a";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path37:before {
    content: "\e99b";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path38:before {
    content: "\e99c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path39:before {
    content: "\e99d";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path40:before {
    content: "\e99e";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path41:before {
    content: "\e99f";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path42:before {
    content: "\e9a0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path43:before {
    content: "\e9a1";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path44:before {
    content: "\e9a2";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path45:before {
    content: "\e9a3";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path46:before {
    content: "\e9a4";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path47:before {
    content: "\e9a5";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path48:before {
    content: "\e9a6";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path49:before {
    content: "\e9a7";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path50:before {
    content: "\e9a8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path51:before {
    content: "\e9a9";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path52:before {
    content: "\e9aa";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path53:before {
    content: "\e9ab";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path54:before {
    content: "\e9ac";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path55:before {
    content: "\e9ad";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path56:before {
    content: "\e9ae";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path57:before {
    content: "\e9af";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path58:before {
    content: "\e9b0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path59:before {
    content: "\e9b1";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path60:before {
    content: "\e9b2";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path61:before {
    content: "\e9b3";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path62:before {
    content: "\e9b4";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path63:before {
    content: "\e9b5";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path64:before {
    content: "\e9b6";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path65:before {
    content: "\e9b7";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path66:before {
    content: "\e9b8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path67:before {
    content: "\e9ba";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path68:before {
    content: "\e9bc";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path69:before {
    content: "\e9bd";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path70:before {
    content: "\e9be";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path71:before {
    content: "\e9bf";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path72:before {
    content: "\e9c0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path73:before {
    content: "\e9c1";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path74:before {
    content: "\e9c2";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path75:before {
    content: "\e9c4";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path76:before {
    content: "\e9c5";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path77:before {
    content: "\e9c6";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path78:before {
    content: "\e9c8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path79:before {
    content: "\e9c9";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path80:before {
    content: "\e9ca";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path81:before {
    content: "\e9cb";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path82:before {
    content: "\e9cc";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path83:before {
    content: "\e9cd";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path84:before {
    content: "\e9ce";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path85:before {
    content: "\e9cf";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path86:before {
    content: "\e9d0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path87:before {
    content: "\e9d1";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path88:before {
    content: "\e9d2";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path89:before {
    content: "\e9d3";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path90:before {
    content: "\e9d4";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path91:before {
    content: "\e9d5";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path92:before {
    content: "\e9d6";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path93:before {
    content: "\e9d7";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path94:before {
    content: "\e9d8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path95:before {
    content: "\e9d9";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path96:before {
    content: "\e9da";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path97:before {
    content: "\e9db";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path98:before {
    content: "\e9dc";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path99:before {
    content: "\e9dd";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path100:before {
    content: "\e9de";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path101:before {
    content: "\e9df";
    margin-left: -1em;
    color: rgb(226, 226, 226);
}
.safari .path102:before {
    content: "\e9e0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path103:before {
    content: "\e9e1";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path104:before {
    content: "\e9e2";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path105:before {
    content: "\e9e3";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path106:before {
    content: "\e9e4";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path107:before {
    content: "\e9e5";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path108:before {
    content: "\e9e6";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path109:before {
    content: "\e9e7";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path110:before {
    content: "\e9e8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path111:before {
    content: "\e9e9";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path112:before {
    content: "\e9ea";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path113:before {
    content: "\e9eb";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path114:before {
    content: "\e9ec";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path115:before {
    content: "\e9ed";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path116:before {
    content: "\e9ee";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path117:before {
    content: "\e9ef";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path118:before {
    content: "\e9f0";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path119:before {
    content: "\e9f1";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path120:before {
    content: "\e9f2";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path121:before {
    content: "\e9f3";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path122:before {
    content: "\e9f4";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path123:before {
    content: "\e9f5";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path124:before {
    content: "\e9f6";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path125:before {
    content: "\e9f7";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path126:before {
    content: "\e9f8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path127:before {
    content: "\e9f9";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path128:before {
    content: "\e9fa";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path129:before {
    content: "\e9fb";
    margin-left: -1em;
    color: rgb(243, 243, 243);
}
.safari .path130:before {
    content: "\e9fc";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.safari .path131:before {
    content: "\e9fd";
    margin-left: -1em;
    color: rgb(205, 21, 30);
}
.safari .path132:before {
    content: "\e9fe";
    margin-left: -1em;
    color: rgb(250, 81, 83);
}
.safari .path133:before {
    content: "\e9ff";
    margin-left: -1em;
    color: rgb(172, 172, 172);
}
.safari .path134:before {
    content: "\ea00";
    margin-left: -1em;
    color: rgb(238, 238, 238);
}
.lucidchart:before {
    content: "\ea01";
    color: #f96b13;
}
.chrome .path1:before {
    content: "\e96f";
    color: rgb(239, 63, 54);
}
.chrome .path2:before {
    content: "\e970";
    margin-left: -1em;
    color: rgb(252, 217, 0);
}
.chrome .path3:before {
    content: "\e971";
    margin-left: -1em;
    color: rgb(97, 188, 91);
}
.chrome .path4:before {
    content: "\e972";
    margin-left: -1em;
    color: rgb(90, 176, 85);
}
.chrome .path5:before {
    content: "\e973";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.chrome .path6:before {
    content: "\e974";
    margin-left: -1em;
    color: rgb(16, 114, 186);
}
.chrome .path7:before {
    content: "\e975";
    margin-left: -1em;
    color: rgb(234, 202, 5);
}
.chrome .path8:before {
    content: "\e976";
    margin-left: -1em;
    color: rgb(223, 58, 50);
}
.mode:before {
    content: "\e95f";
    color: #38b068;
}
.tableau .path1:before {
    content: "\e960";
    color: rgb(232, 118, 45);
}
.tableau .path2:before {
    content: "\e961";
    margin-left: -1em;
    color: rgb(199, 32, 55);
}
.tableau .path3:before {
    content: "\e962";
    margin-left: -1em;
    color: rgb(91, 135, 155);
}
.tableau .path4:before {
    content: "\e963";
    margin-left: -1em;
    color: rgb(92, 102, 146);
}
.tableau .path5:before {
    content: "\e964";
    margin-left: -1em;
    color: rgb(235, 145, 41);
}
.tableau .path6:before {
    content: "\e965";
    margin-left: -1em;
    color: rgb(92, 102, 146);
}
.tableau .path7:before {
    content: "\e966";
    margin-left: -1em;
    color: rgb(31, 69, 126);
}
.tableau .path8:before {
    content: "\e967";
    margin-left: -1em;
    color: rgb(113, 153, 166);
}
.ludus .path1:before {
    content: "\e968";
    color: rgb(0, 0, 0);
}
.ludus .path2:before {
    content: "\e969";
    margin-left: -1em;
    color: rgb(78, 86, 255);
}
.zendesk:before {
    content: "\e92b";
}
.intercom:before {
    content: "\e959";
    color: #1f8ded;
}
.awesome-table .path1:before {
    content: "\e90f";
    color: rgb(255, 193, 7);
}
.awesome-table .path2:before {
    content: "\e911";
    margin-left: -1em;
    color: rgb(255, 160, 0);
}
.awesome-table .path3:before {
    content: "\e912";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.google-calendar .path1:before {
    content: "\e94f";
    color: rgb(255, 255, 255);
}
.google-calendar .path2:before {
    content: "\e950";
    margin-left: -1em;
    color: rgb(234, 67, 53);
}
.google-calendar .path3:before {
    content: "\e951";
    margin-left: -1em;
    color: rgb(251, 188, 4);
}
.google-calendar .path4:before {
    content: "\e952";
    margin-left: -1em;
    color: rgb(52, 168, 83);
}
.google-calendar .path5:before {
    content: "\e953";
    margin-left: -1em;
    color: rgb(24, 128, 56);
}
.google-calendar .path6:before {
    content: "\e954";
    margin-left: -1em;
    color: rgb(25, 103, 210);
}
.google-calendar .path7:before {
    content: "\e955";
    margin-left: -1em;
    color: rgb(66, 133, 244);
}
.google-calendar .path8:before {
    content: "\e902";
    margin-left: -1em;
    color: rgb(66, 133, 244);
}
.kipwise:before {
    content: "\e94c";
    color: #00c4db;
}
.dropbox:before {
    content: "\e936";
    color: #0061ff;
}
.asana:before {
    content: "\e928";
    color: #f95353;
}
.github:before {
    content: "\e929";
}
.slack .path1:before {
    content: "\e918";
    color: rgb(224, 30, 90);
}
.slack .path2:before {
    content: "\e919";
    margin-left: -1em;
    color: rgb(224, 30, 90);
}
.slack .path3:before {
    content: "\e91a";
    margin-left: -1em;
    color: rgb(54, 197, 240);
}
.slack .path4:before {
    content: "\e91b";
    margin-left: -1em;
    color: rgb(54, 197, 240);
}
.slack .path5:before {
    content: "\e91c";
    margin-left: -1em;
    color: rgb(46, 182, 125);
}
.slack .path6:before {
    content: "\e91d";
    margin-left: -1em;
    color: rgb(46, 182, 125);
}
.slack .path7:before {
    content: "\e91e";
    margin-left: -1em;
    color: rgb(236, 178, 46);
}
.slack .path8:before {
    content: "\e91f";
    margin-left: -1em;
    color: rgb(236, 178, 46);
}
.marvel:before {
    content: "\e917";
    color: #1991eb;
}
.invision:before {
    content: "\e903";
    color: #dc395f;
}
.trello:before {
    content: "\e909";
    color: #0083d0;
}
.figma .path1:before {
    content: "\e90a";
    color: rgb(10, 207, 131);
}
.figma .path2:before {
    content: "\e914";
    margin-left: -1em;
    color: rgb(162, 89, 255);
}
.figma .path3:before {
    content: "\e915";
    margin-left: -1em;
    color: rgb(242, 78, 30);
}
.figma .path4:before {
    content: "\e916";
    margin-left: -1em;
    color: rgb(255, 114, 98);
}
.figma .path5:before {
    content: "\e920";
    margin-left: -1em;
    color: rgb(26, 188, 254);
}
.confluence:before {
    content: "\e921";
    color: #0052cc;
}
.google-drive .path1:before {
    content: "\e922";
    color: rgb(0, 102, 218);
}
.google-drive .path2:before {
    content: "\e923";
    margin-left: -1em;
    color: rgb(0, 172, 71);
}
.google-drive .path3:before {
    content: "\e924";
    margin-left: -1em;
    color: rgb(234, 67, 53);
}
.google-drive .path4:before {
    content: "\e90d";
    margin-left: -1em;
    color: rgb(0, 131, 45);
}
.google-drive .path5:before {
    content: "\e90e";
    margin-left: -1em;
    color: rgb(38, 132, 252);
}
.google-drive .path6:before {
    content: "\e910";
    margin-left: -1em;
    color: rgb(255, 186, 0);
}
.airtable .path1:before {
    content: "\e925";
    color: rgb(255, 191, 0);
}
.airtable .path2:before {
    content: "\e926";
    margin-left: -1em;
    color: rgb(38, 181, 248);
}
.airtable .path3:before {
    content: "\e927";
    margin-left: -1em;
    color: rgb(237, 48, 73);
}
.airtable .path4:before {
    content: "\e939";
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.25;
}
.brand-chrome:before {
    content: "\e96c";
}
.brand-slack:before {
    content: "\e96d";
}
.brand-kipwise:before {
    content: "\e94b";
}
.brand-confluence:before {
    content: "\e900";
}
.brand-google-drive:before {
    content: "\e901";
}
.h1:before {
    content: "\e904";
}
.h2:before {
    content: "\e905";
}
.h3:before {
    content: "\e906";
}
.h4:before {
    content: "\e93a";
}
.h5:before {
    content: "\e93c";
}
.checklist:before {
    content: "\e941";
}
.text:before {
    content: "\e908";
}
.divider:before {
    content: "\e907";
}
.reading:before {
    content: "\eacb";
}
.file-csv:before {
    content: "\eaca";
}
.img-align-center:before {
    content: "\eac6";
}
.img-align-left:before {
    content: "\eac7";
}
.img-align-right:before {
    content: "\eac8";
}
.off-filter:before {
    content: "\eac1";
}
.save-filter:before {
    content: "\eac2";
}
.save-cloud:before {
    content: "\eac3";
}
.latex:before {
    content: "\eabe";
}
.shortcut-overlay:before {
    content: "\eaad";
}
.date:before {
    content: "\eaa9";
}
.datetime:before {
    content: "\eaac";
}
.print:before {
    content: "\eaab";
}
.footnote:before {
    content: "\eaaa";
}
.globe-americas:before {
    content: "\eaa5";
}
.globe-asia:before {
    content: "\eaa6";
}
.globe-europe:before {
    content: "\eaa7";
}
.globe:before {
    content: "\eaa8";
}
.ideas:before {
    content: "\ea97";
}
.email:before {
    content: "\ea91";
}
.education:before {
    content: "\ea90";
}
.reset-checklist:before {
    content: "\ea8f";
}
.learning:before {
    content: "\ea8e";
}
.vertical-collapse:before {
    content: "\ea89";
}
.vertical-expand:before {
    content: "\ea8a";
}
.align-left:before {
    content: "\ea87";
}
.align-right:before {
    content: "\ea88";
}
.star-filled:before {
    content: "\ea85";
}
.star:before {
    content: "\ea86";
}
.gallery:before {
    content: "\ea84";
}
.add-users:before {
    content: "\ea82";
}
.card-view:before {
    content: "\ea83";
}
.placeholder:before {
    content: "\ea7d";
}
.thumbdown:before {
    content: "\ea7b";
}
.thumbup:before {
    content: "\ea7c";
}
.to-read:before {
    content: "\ea79";
}
.todo:before {
    content: "\ea78";
}
.folder-tree:before {
    content: "\ea77";
}
.collapse:before {
    content: "\ea76";
}
.pin-outline:before {
    content: "\ea74";
}
.pin:before {
    content: "\ea75";
}
.heart-filled:before {
    content: "\ea6e";
}
.heart:before {
    content: "\ea6f";
}
.drag:before {
    content: "\ea6c";
}
.play-circle:before {
    content: "\ea66";
}
.customization:before {
    content: "\ea64";
}
.flag:before {
    content: "\ea65";
}
.billing:before {
    content: "\ea63";
}
.arrow-down:before {
    content: "\ea5f";
}
.arrow-left:before {
    content: "\ea61";
}
.arrow-right:before {
    content: "\ea60";
}
.arrow-up:before {
    content: "\ea62";
}
.tag:before {
    content: "\ea57";
}
.library:before {
    content: "\ea58";
}
.integrations:before {
    content: "\ea59";
}
.analytics:before {
    content: "\ea5a";
}
.emoji-wink:before {
    content: "\ea50";
}
.emoji:before {
    content: "\ea51";
}
.refresh:before {
    content: "\ea52";
}
.clear-filter:before {
    content: "\ea53";
}
.filter:before {
    content: "\ea54";
}
.grid-view:before {
    content: "\ea55";
}
.list-view:before {
    content: "\ea56";
}
.toc:before {
    content: "\ea16";
}
.caret-left:before {
    content: "\ea12";
}
.caret-top:before {
    content: "\ea14";
}
.caret-right:before {
    content: "\ea15";
}
.caret-down:before {
    content: "\ea4c";
}
.fullscreen:before {
    content: "\ea4e";
}
.exit-fullscreen:before {
    content: "\ea4f";
}
.comment:before {
    content: "\ea4d";
}
.click:before {
    content: "\ea4b";
}
.share:before {
    content: "\ea4a";
}
.settings:before {
    content: "\ea49";
}
.move-folder:before {
    content: "\ea48";
}
.logout:before {
    content: "\ea47";
}
.sort-asc:before {
    content: "\ea44";
}
.sort-desc:before {
    content: "\ea45";
}
.sort:before {
    content: "\ea46";
}
.notes:before {
    content: "\ea42";
}
.copy:before {
    content: "\ea43";
}
.export:before {
    content: "\ea37";
}
.work:before {
    content: "\ea38";
}
.people:before {
    content: "\ea39";
}
.reply:before {
    content: "\ea3a";
}
.send:before {
    content: "\ea3b";
}
.edit:before {
    content: "\ea3c";
}
.check:before {
    content: "\ea3d";
}
.invisible:before {
    content: "\ea36";
}
.search:before {
    content: "\ea35";
}
.upload:before {
    content: "\ea32";
}
.disconnected:before {
    content: "\ea33";
}
.history:before {
    content: "\ea34";
}
.writing:before {
    content: "\ea31";
}
.broadcast:before {
    content: "\ea30";
}
.announcement:before {
    content: "\ea2f";
}
.hash:before {
    content: "\ea1a";
}
.plus:before {
    content: "\ea19";
}
.menu:before {
    content: "\ea13";
}
.menu-hide:before {
    content: "\ea18";
}
.alert-circle:before {
    content: "\e95c";
}
.checked-circle:before {
    content: "\e95d";
}
.question-circle:before {
    content: "\e95e";
}
.minus-circle:before {
    content: "\e96e";
}
.content-link:before {
    content: "\e96b";
}
.create-folder:before {
    content: "\ea17";
}
.information:before {
    content: "\ea02";
}
.notification-on:before {
    content: "\ea1b";
}
.content-image:before {
    content: "\e957";
}
.help-center:before {
    content: "\e958";
}
.content-document-to-review:before {
    content: "\e95b";
}
.strikethrough:before {
    content: "\ea3e";
}
.underline:before {
    content: "\ea3f";
}
.italic:before {
    content: "\ea40";
}
.bold:before {
    content: "\ea41";
}
.cloud-upload:before {
    content: "\e9c3";
}
.download:before {
    content: "\e9c7";
}
.video:before {
    content: "\e913";
}
.quote:before {
    content: "\e978";
}
.ol:before {
    content: "\e9b9";
}
.ul:before {
    content: "\e9bb";
}
.table:before {
    content: "\ea71";
}
.code:before {
    content: "\ea80";
}
.warning:before {
    content: "\e92a";
}
.permission-team:before {
    content: "\e92c";
}
.permission-private:before {
    content: "\e92e";
}
.permission-people:before {
    content: "\ea11";
}
.more-hori:before {
    content: "\e92f";
}
.more-vert:before {
    content: "\e930";
}
.drafts:before {
    content: "\e938";
}
.open-in-new:before {
    content: "\e92d";
}
.arrange:before {
    content: "\e933";
}
.contact-support:before {
    content: "\e93e";
}
.attachment:before {
    content: "\e90c";
}
.add-image:before {
    content: "\e90b";
}
.whats-new:before {
    content: "\e93f";
}
.image:before {
    content: "\e931";
}
.trash:before {
    content: "\e93b";
}
